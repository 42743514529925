<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-challan-item-modal" title="Challan Item Add" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="name" v-validate="'required|max:255'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter item name"/>
              <div class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="unit" class="col-form-label col-form-label-sm">Unit <span class="custom-required">*</span></label>
              <input type="text" v-model="unit" v-validate="'required|max:255'" id="unit" name="unit" class="form-control form-control-sm" placeholder="Enter item unit"/>
              <div class="invalid-feedback">{{ errors.first('unit') }}</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-challan-item-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'addChallanItem',
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      name: '',
      unit: '',
    }
  },
  mounted() {},
  methods: {
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('unit', this.unit)
          apiClient.post('api/challan-items/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            this.$notification.success({
              message: response.data.message,
            })
            $('#challan_item_list').DataTable().destroy()
            this.resetForm()
            this.$emit('getChallanItems')
            this.$bvModal.hide('add-challan-item-modal')
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    resetForm() {
      this.name = ''
      this.unit = ''
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
