<template>
  <div>
    <div class="mb-5">
      <b-modal id="update-challan-item-modal" title="Challan Item Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="name" v-validate="'required|max:255'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter item name"/>
              <div class="invalid-feedback">{{ errors.first('name') }}</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="unit" class="col-form-label col-form-label-sm">Unit <span class="custom-required">*</span></label>
              <input type="text" v-model="unit" v-validate="'required|max:255'" id="unit" name="unit" class="form-control form-control-sm" placeholder="Enter item unit"/>
              <div class="invalid-feedback">{{ errors.first('unit') }}</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('update-challan-item-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'updateChallanItem',
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      chllanItemId: '',
      name: '',
      unit: '',
    }
  },
  mounted() {},
  methods: {
    onUpdate(chllanItemId) {
      this.loading = true
      apiClient.get('api/challan-items/' + chllanItemId + '/edit').then(response => {
        this.loading = false
        if (!response.data.error) {
          const data = response.data.challan_item
          this.chllanItemId = data.id
          this.name = data.name
          this.unit = data.unit
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('unit', this.unit)
          apiClient.post('api/challan-items/update/' + this.chllanItemId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#challan_item_list').DataTable().destroy()
                this.$bvModal.hide('update-challan-item-modal')
                this.$emit('getChallanItems')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
