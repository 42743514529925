<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add Challan Item</span></template>
          <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-challan-item-modal @click="$bvModal.show('add-challan-item-modal'), resetForm()">
            <i class="fa fa-plus"/> Add Challan Item
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="challan_item_list">
          <thead>
          <tr>
            <th>SL</th>
            <th>Name</th>
            <th>Picture</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(challan_item, index) in challan_items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ challan_item.name }}</td>
            <td>{{ challan_item.unit }}</td>
            <td>
              <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(challan_item.id), $bvModal.show('update-challan-item-modal')"><i class="fa fa-edit mr-1"></i>Update</a>
              <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(challan_item.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="challan_items.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-challan-item
      @getChallanItems="getChallanItems"
      ref="addChallanItem">
    </add-challan-item>
    <update-challan-item
      @getChallanItems="getChallanItems"
      ref="updateChallanItem">
    </update-challan-item>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addChallanItem from '@/views/challanItem/modal/addChallanItem'
import updateChallanItem from '@/views/challanItem/modal/updateChallanItem'
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, addChallanItem, updateChallanItem },
  data() {
    return {
      challan_items: [],
      challan_item: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getChallanItems()
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    getChallanItems() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/challan-items')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.challan_items = response.data.challan_items
          setTimeout(function() {
            $('#challan_item_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(challanItemId) {
      this.$refs.updateChallanItem.onUpdate(challanItemId)
      this.$bvModal.show('update-challan-item-modal')
    },
    onDelete(challanItemId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/challan-items/delete/' + challanItemId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#challan_item_list').DataTable().destroy()
              this.getChallanItems()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.show = false
      this.btnEdit = false
      this.challan_item = {}
    },
  },
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
